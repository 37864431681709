@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "../../../assets/scss/function";

.Header {
  align-items: center;
  background-color: $dark_blue;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 60px;
  width: 100%;
  overflow: hidden;

  @include breakpoint(desktop-small) {
    padding-top: 120px;
  }

  .container-header-top-desktop {
    display: none;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(desktop-small) {
      display: flex;
    }

    img {
      display: none;
      height: 20%;
      left: -5%;
      max-width: 919px;
      position: relative;
      width: 35%;

      @include breakpoint(desktop-small) {
        display: flex;
        width: 40%;
        height: 40%;
      }

      @include breakpoint(desktop-medium) {
        width: 45%;
      }
    }

    .info-header {
      padding: 0 30px 20px;

      @include breakpoint(desktop-medium) {
        padding: 0 80px 38px;
      }

      @include breakpoint(desktop-large) {
        padding: 0 192px 38px;
      }

      .info-all-ibm {
        &:last-child {
          margin: 0 0 40px 0;
        }

        &.partner {
          flex-direction: column;
          display: flex;
          gap: 10px;
          
          img {
            position: inherit;
            width: 150px;
          }
        }

        .partner-info {
          margin: 40px 0 10px 0;
        }

        .span-partner {
          font-size: 20px;
        }
      }

      p {
        color: $white;
        font-family: $primary_font;
        font-size: pxToRem(20px);
        margin: 0;

        &.min-text {
          font-size: pxToRem(14px);
          margin: 5px 0;
        }

        &.partner-info {
          font-family: $primary_font_light;
          font-size: 14px !important;
          letter-spacing: 1px;
          margin: 50px 0 10px 0;
        }

        &:first-child {
          font-family: $primary_font_medium;
          font-size: pxToRem(15px);
        }

        @include breakpoint(desktop-medium) {
          font-size: pxToRem(29px);

          &:first-child {
            font-size: pxToRem(32px);
          }
        }
      }

      h1 {
        color: $white;
        font-family: $primary_font_demi;
        font-size: pxToRem(60px);
        margin: 0 0 12px 0;

        span {
          color: $aqua_blue;
          display: inline-block;
          transform: matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
        }

        @include breakpoint(desktop-medium) {
          font-size: pxToRem(122px);
        }
      }

      h2 {
        color: $white;
        font-family: $primary_font_demi;
        font-size: pxToRem(46px);
        margin: 0 0 87px 0;

        @include breakpoint(desktop-medium) {
          font-size: pxToRem(60px);
        }
      }

      .Button {
        display: block;
        margin-bottom: 22px;
        text-align: center;
        font-size: pxToRem(24px);
        width: 640px;
        height: 65px;
      }

      .span-partner {
        color: white;
        font-family: $primary_font_heavy;
        font-size: pxToRem(33px);
      }

      .info-letter {
        width: 550px;
        font-size: pxToRem(17px);
        line-height: pxToRem(32px);
        margin: 0 0 40px 0;

        @include breakpoint(desktop-medium) {
          width: 767px;
        }

        &.info-letter-headline {
          font-family: $primary_font_medium;
          font-size: pxToRem(25px);
          font-weight: bold;

          & span {
            color: $aqua_blue;
          }
        }
      }
    }
  }

  .container-header-top-mobile {
    display: flex;
    justify-content: space-between;
    padding: 30px;

    @include breakpoint(desktop-small) {
      display: none;
    }

    .header-partner-mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      img {
        width: 150px;
      }
    }

    img {
      height: auto;
      max-height: 436px;
      max-width: 502px;
      width: 100%;
    }

    .info-header {
      align-items: center;
      display: flex;
      flex-direction: column;

      p {
        color: $white;
        font-family: $primary_font;
        font-size: pxToRem(19px);
        margin: 0;

        &.min-text {
          font-size: pxToRem(14px);
          margin: 5px 0;
        }

        &.partner-info {
          font-family: $primary_font_light;
          font-size: 12px !important;
          letter-spacing: 1px;
          margin: 40px 0 10px 0;
        }

        &:first-child {
          font-family: $primary_font_medium;
          font-size: pxToRem(21px);
        }
      }

      h1 {
        color: $white;
        font-family: $primary_font_demi;
        font-size: pxToRem(96px);
        margin: 0 0 33px 0;

        span {
          color: $aqua_blue;
          display: inline-block;
          transform: matrix(0.99, 0.12, -0.12, 0.99, 0, 0);
        }
      }

      h2 {
        color: $white;
        font-family: $primary_font_demi;
        font-size: pxToRem(30px);
        margin: 0 0 87px 0;

        @include breakpoint(mobile-larger) {
          font-size: pxToRem(35px);
        }

        @include breakpoint(tablet) {
          font-size: pxToRem(40px);
        }
      }

      .Button {
        display: block;
        margin-bottom: 22px;
        text-align: center;
        height: 70px;
        width: 400px;
        font-size: pxToRem(18px);

        @include breakpoint(mobile-larger) {
          width: 560px;
          height: 60px;
          font-size: pxToRem(20px);
        }

        @include breakpoint(tablet) {
          width: 640px;
          height: 65px;
          font-size: pxToRem(24px);
        }
      }

      .span-partner {
        color: white;
        font-family: $primary_font_heavy;
        font-size: pxToRem(33px);
      }

      .info-letter {
        width: 80%;
        font-size: pxToRem(17px);
        line-height: pxToRem(32px);
        margin: 0 0 40px 0;

        &.info-letter-headline {
          font-family: $primary_font_medium;
          font-size: pxToRem(25px);
          font-weight: bold;

          & span {
            color: $aqua_blue;
          }
        }
      }
    }
  }

  .container-infos-course {
    color: white;
    width: 100%;

    @include breakpoint(desktop-small) {
      width: calc(100% - 100px);
    }

    @include breakpoint(desktop-medium) {
      width: calc(100% - 384px);
    }

    .line {
      border-top: 2px solid white;
      display: none;
      margin-bottom: 52px;
      opacity: 0.15;

      @include breakpoint(desktop-small) {
        display: flex;
      }
    }

    .container-courses {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding: 60px 30px 30px 30px;

      @include breakpoint(desktop-small) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
      }

      .box-container {
        display: flex;
        margin-bottom: 70px;

        span {
          background: #01c7f0 0% 0% no-repeat padding-box;
          border-radius: 11px;
          color: #01c7f0;
          display: flex;
          height: 25px;
          max-width: 25px;
          transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0);
          width: 25px;

          @include breakpoint(desktop-small) {
            display: none;
          }
        }

        .box-course {
          max-width: 244px;
          display: flex;
          align-items: flex-start;

          div {
            position: relative;
            top: -7px;

            p {
              color: $white;
              font-family: $primary_font;
              font-size: pxToRem(18px);
              margin: 0 0 0 20px;

              strong {
                font-family: $primary_font_demi;
                font-size: pxToRem(16px);
                margin-bottom: 21px;

                &.blue {
                  color: $aqua_blue;
                  font-size: pxToRem(18px);
                }
              }
            }
          }

          @include breakpoint(desktop-small) {
            div {
              p {
                margin-bottom: 20px;
                font-size: pxToRem(13px);

                strong {
                  font-size: pxToRem(20px);

                  &.blue {
                    color: $aqua_blue;
                    font-size: pxToRem(20px);
                  }
                }
              }
            }
          }

          @include breakpoint(desktop-medium) {
            div {
              p {
                margin-bottom: 20px;
                font-size: pxToRem(20px);

                strong {
                  font-size: pxToRem(28px);

                  &.blue {
                    color: $aqua_blue;
                    font-size: pxToRem(20px);
                  }
                }
              }
            }
          }
        }

        @include breakpoint(desktop-medium) {
          .box-course {
            max-width: 344px;
          }
        }
      }
    }
  }
}
